import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const HomePage = () => {
  const { isAuthenticated, logout } = useAuth();

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <nav className="bg-white shadow-md p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">ParkedPal</h1>
          <div className="space-x-4">
            <Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link>
            <Link to="/report-issue" className="text-gray-600 hover:text-gray-900">Report Issue</Link>
            {isAuthenticated ? (
              <>
                <Link to="/generate-qr" className="text-gray-600 hover:text-gray-900">Generate QR</Link>
                <Link to="/dashboard" className="text-gray-600 hover:text-gray-900">Dashboard</Link>
                <button onClick={logout} className="text-red-600 hover:text-red-800">Logout</button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-gray-600 hover:text-gray-900">Login</Link>
                <Link to="/register" className="text-gray-600 hover:text-gray-900">Register</Link>
              </>
            )}
          </div>
        </div>
      </nav>
      <main className="flex-grow container mx-auto p-4 overflow-auto">
        <div className="h-full flex flex-col justify-center items-center">
          <h1 className="text-4xl font-bold mb-6">Welcome to ParkedPal</h1>
          <p className="text-xl mb-8">Connecting car owners with concerned citizens</p>
          {!isAuthenticated && (
            <div className="space-x-4">
              <Link to="/login" className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                Login
              </Link>
              <Link to="/register" className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
                Register
              </Link>
            </div>
          )}
        </div>
      </main>
      <footer className="bg-gray-200 p-4 text-center">
        <p>&copy; 2023 ParkedPal. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;