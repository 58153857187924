import React, { useState, useEffect } from 'react';
import dataProvider from '../utils/api';

const UserDashboard = () => {
  const [issues, setIssues] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await dataProvider.getList('issues', { pagination: { page: 1, perPage: 10 }, sort: { field: 'date', order: 'DESC' }, filter: {} });
      setIssues(response.data);
    } catch (error) {
      console.error('Error fetching issues:', error);
      setError('Failed to fetch issues. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteAllIssues = async () => {
    if (window.confirm('Are you sure you want to delete all issues? This action cannot be undone.')) {
      setIsLoading(true);
      setError(null);
      try {
        await dataProvider.deleteMany('issues', { ids: [] });
        setIssues([]);
      } catch (error) {
        console.error('Error deleting all issues:', error);
        setError('Failed to delete all issues. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString + 'Z'); 
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return date.toLocaleString(undefined, {
      dateStyle: 'medium',
      timeStyle: 'short',
      timeZone: browserTimeZone
    });
  };

  return (
    <div className="h-full overflow-auto p-4">
      <h2 className="text-2xl font-bold mb-6">User Dashboard</h2>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Recent Issues</h3>
          <button
            onClick={handleDeleteAllIssues}
            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            disabled={isLoading || issues.length === 0}
          >
            Delete All Issues
          </button>
        </div>
        {isLoading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : issues.length > 0 ? (
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left">Date & Time</th>
                <th className="px-4 py-2 text-left">License Plate</th>
                <th className="px-4 py-2 text-left">Message</th>
                <th className="px-4 py-2 text-left">Notification Sent</th>              
              </tr>
            </thead>
            <tbody>
              {issues.map((issue) => (
                <tr key={issue.id} className="border-b">
                  <td className="px-4 py-2">{formatDate(issue.date)}</td>
                  <td className="px-4 py-2">{issue.license_plate}</td>
                  <td className="px-4 py-2">{issue.description}</td>
                  <td className="px-4 py-2">{issue.notification_sent ? 'Sent' : 'Not Sent'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No recent issues reported.</p>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;