import React, { useState, useEffect } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import dataProvider from '../utils/api';

const FRONTEND_HOST = process.env.REACT_FRONTEND_HOST || 'http://localhost:3000';

const GenerateQR = () => {
  const [vehicles, setVehicles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [currentVehicle, setCurrentVehicle] = useState({ id: null, make: '', model: '', year: '', license_plate: '' });
  const [errors, setErrors] = useState({});
  const [backendError, setBackendError] = useState('');

  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    try {
      const response = await dataProvider.getList('vehicles', { pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' }, filter: {} });
      setVehicles(response.data);
    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setBackendError('Failed to fetch vehicles. Please try again.');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!currentVehicle.make.trim()) newErrors.make = 'Make is required';
    if (!currentVehicle.model.trim()) newErrors.model = 'Model is required';
    if (!currentVehicle.year) {
      newErrors.year = 'Year is required';
    } else if (isNaN(currentVehicle.year) || currentVehicle.year < 1900 || currentVehicle.year > new Date().getFullYear() + 1) {
      newErrors.year = 'Please enter a valid year';
    }
    if (!currentVehicle.license_plate.trim()) {
      newErrors.license_plate = 'License plate is required';
    } else if (!/^[A-Za-z0-9 -]{1,20}$/.test(currentVehicle.license_plate)) {
      newErrors.license_plate = 'Please enter a valid license plate (up to 20 alphanumeric characters, spaces, and hyphens)';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBackendError('');
    if (!validateForm()) return;

    try {
      if (currentVehicle.id) {
        await dataProvider.update('vehicles', { id: currentVehicle.id, data: currentVehicle });
      } else {
        await dataProvider.create('vehicles', { data: currentVehicle });
      }
      fetchVehicles();
      setIsModalOpen(false);
      setCurrentVehicle({ id: null, make: '', model: '', year: '', license_plate: '' });
    } catch (error) {
      console.error('Error saving vehicle:', error);
      setBackendError(error.response?.data?.detail || 'Failed to save vehicle. Please try again.');
    }
  };

  const openModal = (vehicle = { id: null, make: '', model: '', year: '', license_plate: '' }) => {
    setCurrentVehicle(vehicle);
    setErrors({});
    setBackendError('');
    setIsModalOpen(true);
  };

  const openQRModal = (vehicle) => {
    setCurrentVehicle(vehicle);
    setIsQRModalOpen(true);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this vehicle?')) {
      try {
        await dataProvider.delete('vehicles', { id });
        fetchVehicles();
      } catch (error) {
        console.error('Error deleting vehicle:', error);
        setBackendError('Failed to delete vehicle. Please try again.');
      }
    }
  };

  const getQRCodeUrl = (licensePlate) => {
    return `${FRONTEND_HOST}/report-issue/${encodeURIComponent(licensePlate)}`;
  };

  return (
    <div className="h-full p-6">
      <h2 className="text-2xl font-bold mb-6">Vehicle QR Codes</h2>
      <button
        onClick={() => openModal()}
        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-6"
      >
        Add New Vehicle
      </button>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2 text-left">Make</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Model</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Year</th>
              <th className="border border-gray-300 px-4 py-2 text-left">License Plate</th>
              <th className="border border-gray-300 px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {vehicles.map((vehicle) => (
              <tr key={vehicle.id} className="hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{vehicle.make}</td>
                <td className="border border-gray-300 px-4 py-2">{vehicle.model}</td>
                <td className="border border-gray-300 px-4 py-2">{vehicle.year}</td>
                <td className="border border-gray-300 px-4 py-2">{vehicle.license_plate}</td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  <button
                    onClick={() => openModal(vehicle)}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => openQRModal(vehicle)}
                    className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    QR Code
                  </button>
                  <button
                    onClick={() => handleDelete(vehicle.id)}
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-2xl font-bold mb-6 text-center">
              {currentVehicle.id ? 'Edit Vehicle' : 'Add New Vehicle'}
            </h3>
            {backendError && (
              <p className="text-red-500 mb-4 text-center">{backendError}</p>
            )}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="make" className="block text-sm font-medium text-gray-700 mb-1">Make</label>
                <input
                  id="make"
                  type="text"
                  value={currentVehicle.make}
                  onChange={(e) => setCurrentVehicle({ ...currentVehicle, make: e.target.value })}
                  className={`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ${errors.make ? 'border-red-500' : ''}`}
                  placeholder="Make"
                />
                {errors.make && <p className="text-red-500 text-xs mt-1">{errors.make}</p>}
              </div>
              <div>
                <label htmlFor="model" className="block text-sm font-medium text-gray-700 mb-1">Model</label>
                <input
                  id="model"
                  type="text"
                  value={currentVehicle.model}
                  onChange={(e) => setCurrentVehicle({ ...currentVehicle, model: e.target.value })}
                  className={`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ${errors.model ? 'border-red-500' : ''}`}
                  placeholder="Model"
                />
                {errors.model && <p className="text-red-500 text-xs mt-1">{errors.model}</p>}
              </div>
              <div>
                <label htmlFor="year" className="block text-sm font-medium text-gray-700 mb-1">Year</label>
                <input
                  id="year"
                  type="number"
                  value={currentVehicle.year}
                  onChange={(e) => setCurrentVehicle({ ...currentVehicle, year: e.target.value })}
                  className={`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ${errors.year ? 'border-red-500' : ''}`}
                  placeholder="Year"
                />
                {errors.year && <p className="text-red-500 text-xs mt-1">{errors.year}</p>}
              </div>
              <div>
                <label htmlFor="license_plate" className="block text-sm font-medium text-gray-700 mb-1">License Plate</label>
                <input
                  id="license_plate"
                  type="text"
                  value={currentVehicle.license_plate}
                  onChange={(e) => setCurrentVehicle({ ...currentVehicle, license_plate: e.target.value.toUpperCase() })}
                  className={`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md ${errors.license_plate ? 'border-red-500' : ''}`}
                  placeholder="License Plate"
                />
                {errors.license_plate && <p className="text-red-500 text-xs mt-1">{errors.license_plate}</p>}
              </div>
              <div className="flex justify-between pt-4">
                <button
                  type="submit"
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isQRModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
            <h3 className="text-2xl font-bold mb-6">QR Code for {currentVehicle.license_plate}</h3>
            <div className="flex justify-center mb-6">
              <QRCodeSVG
                value={getQRCodeUrl(currentVehicle.license_plate)}
                size={200}
              />
            </div>
            <p className="mb-4">Print this QR code and place it on your vehicle</p>
            <div className="mb-6">
              <p className="text-sm font-medium text-gray-700 mb-2">QR Code URL:</p>
              <input
                type="text"
                value={getQRCodeUrl(currentVehicle.license_plate)}
                readOnly
                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                onClick={(e) => e.target.select()}
              />
            </div>
            <button
              onClick={() => setIsQRModalOpen(false)}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GenerateQR;