import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import dataProvider from '../utils/api';

const ReportIssue = () => {
  const { license_plate } = useParams();
  const [issue, setIssue] = useState('');
  const [inputLicensePlate, setInputLicensePlate] = useState(license_plate);
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dataProvider.create('issues', { data: { license_plate: inputLicensePlate, description: issue } })
      .then(result => {
        setResponse(JSON.stringify(result.data.message));
        console.log(result.data);
        setSubmitted(true);
        setShowPopup(true);
      })
      .catch(error => {
        console.error('Error reporting issue:', error);
        setResponse(JSON.stringify(error.response.data.message) || 'Failed to submit issue. Please try again.' );
        setShowPopup(true);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <nav className="bg-white shadow-md p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">ParkedPal</h1>
          <div className="space-x-4">
            <Link to="/" className="text-gray-600 hover:text-gray-900">Home</Link>
          </div>
        </div>
      </nav>
      <main className="flex-grow container mx-auto p-4 overflow-auto">
        <div className="h-full flex flex-col items-center justify-center">
          <h2 className="text-2xl font-bold mb-6">Report an Issue</h2>
          {!submitted ? (
            <form onSubmit={handleSubmit} className="w-full max-w-sm">
              <div className="mb-4">
                <label htmlFor="license_plate" className="block text-gray-700 text-sm font-bold mb-2">
                  Vehicle Information
                </label>
                <input
                  type="text"
                  id="license_plate"
                  defaultValue={license_plate}
                  onChange={(e) => setInputLicensePlate(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter vehicle information"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="issue" className="block text-gray-700 text-sm font-bold mb-2">
                  Describe the Issue
                </label>
                <textarea
                  id="issue"
                  value={issue}
                  onChange={(e) => setIssue(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  rows="4"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit Report
              </button>
            </form>
          ) : (
            <div className="text-center">
              <p className="text-xl mb-4">Thank you for reporting the issue!</p>
              <p>The vehicle owner has been notified.</p>
              <Link to="/" className="mt-4 inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                Back to Home
              </Link>
            </div>
          )}
        </div>
      </main>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <p className="text-lg mb-4">
              {response}
            </p>
            <button
              onClick={closePopup}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
      <footer className="bg-gray-200 p-4 text-center">
        <p>&copy; 2023 ParkedPal. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ReportIssue;